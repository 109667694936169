import { Summary, Details, PintDetails } from "../../api/common/Types";

const baseUrl = "https://func-airs-prd-aue-001.azurewebsites.net"


export const load = async (folder?: string, token?: string) => {
    var res = await fetch(`${baseUrl}/api/Index?s=${folder ?? ''}&t=${encodeURIComponent(token ?? '')}`, {
        method: 'GET',
        credentials: 'include'
    });
    return await res.json();
}

export const vote = async (url: string, v: number) => {
    await fetch(`${baseUrl}/api/Vote?url=${encodeURIComponent(url)}&vote=${v}`, {
        method: 'GET',
        credentials: 'include'
    });
}

export const setPin = async (url: string, data: PintDetails) => {
    await fetch(`${baseUrl}/api/Pin?url=${encodeURIComponent(url)}&id=${data.id}&pointX=${data.pointX}&pointY=${data.pointY}&comment=${encodeURIComponent(data.comment ?? '')}`, {
        method: 'GET',
        credentials: 'include'
    });
}

export const clearPin = async (url: string, id: string) => {
    await fetch(`${baseUrl}/api/Pin?url=${encodeURIComponent(url)}&id=${id}`, {
        method: 'GET',
        credentials: 'include'
    });
}

export const getImageNumber = (url: string) => {
    var index = url.lastIndexOf("/");
    if(index >= 0) {
        url.substring(index + 1);
    }
    var matches = url.match(/[0-9]+/g);
    if(matches && matches.length > 0) {
        return matches[matches.length - 1];
    }
}

