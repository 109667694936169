import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default function Preview({ images, height, id, className }) {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + id,
      children: 'a',
      pswpModule: () => import('photoswipe'),
      wheelToZoom: true
    });

    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  });

  return <div id={id} className={className}>
    {images.map(img => <a
      href={img.url}
      data-pswp-width={img.width}
      data-pswp-height={img.height}
      target="_blank"
      rel="noreferrer"
    >
      <img src={img.thumbnailUrl} alt="" height={height} />
    </a>)}
  </div>
}
