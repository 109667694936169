import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Summary } from '../../api/common/Types';
import ReactMarkdown from 'react-markdown';
import Preview from './Preview';

export interface SummaryPageProps {
  data: Summary
}

export const SummaryPage: React.FC<SummaryPageProps> = ({ data }) => {
  const { content } = data;
  const navigate = useNavigate();
  return <>{Object.keys(content).filter(r => content[r] ? true : false).map((r, i) => {
    const data = content[r];
    return <React.Fragment key={i}>
      {(data.title || data.body || data.preview) && <div className="card mb-3">
        <div className="row no-gutters">
          {data.preview && <Preview images={[data.preview]} id={`perview-${i}`} height={150} className="col-auto ms-2 mt-2 pe-0" />}
          <div className="col">
            <div className="card-body">
              <div onClick={() => navigate(r)} style={{ cursor: 'pointer' }}>
                {data.title && <h1 className="card-title">{data.title}</h1>}
                {data.body && <ReactMarkdown className="card-text" children={data.body} />}
              </div>
              {data.photos && data.photos.length > 0 ? <Preview images={data.photos} height={200} id="gallery" className="pswp-gallery"/> : <></>}
            </div>
          </div>
        </div>
      </div>}
    </React.Fragment>
  })}
  </>
}
