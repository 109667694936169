import React, { useEffect, useRef, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { getImageNumber, load, vote, setPin, clearPin } from './api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

export default function Gallery({ votes, pins, id, extended, ...props }) {
  var folder = useParams()["*"];

  const lightboxRef = useRef(null);
  const imagesRef = useRef(props.images);
  const tokenRef = useRef(props.token);
  const voteRef = useRef(votes);
  const [, setReset] = useState(0);

  const [imageNumber, setImageNumber] = useState(() => { return localStorage.getItem(folder) ?? ""; })
  const [loading, setLoading] = useState(false);

  const pinsRef = useRef(pins);
  const pinElementRef = useRef(null);

  const getOffset = ()=>{
    const transform = lightboxRef.current.pswp.currSlide.container.style.transform;
    const res = /translate3d\((-?\d+)px, (-?\d+)px/.exec(transform);
    return [parseInt(res[1]), parseInt(res[2])];
  }

  const getZoom = ()=> {
    const style = lightboxRef.current.pswp.currSlide.content.element.style;
    return [parseInt(style.width), parseInt(style.height)];
  }
  
  const onPin = (releasePoint, e)=>{
    if(!lightboxRef.current) {
      return;
    }
    
    let pointX = releasePoint.x;
    let pointY = releasePoint.y;

    const [zoomWidth, zoomHeight] = getZoom();
    const [offsetX, offsetY] = getOffset();

    const existing = pinsRef.current[lightboxRef.current.pswp.currSlide.data.src]
    const comment = (existing && existing.length > 0) ? existing[0].comment : '';

    let newComment = prompt("Provide comments", comment);
    if(newComment == null) {
      return;
    }
    
    var image = imagesRef.current[lightboxRef.current.pswp.currSlide.index];
    var pinDetails = {
      id: "1",
      pointX: (pointX - offsetX) * image.width / zoomWidth,
      pointY: (pointY - offsetY) * image.height / zoomHeight,
      comment: newComment
    };
    pinsRef.current[lightboxRef.current.pswp.currSlide.data.src] = [pinDetails]

    if(pinElementRef.current != null) {
      pinElementRef.current.style.left = (pointX - 12) + "px";
      pinElementRef.current.style.top = (pointY - 24) + "px";
      pinElementRef.current.style.display = "";
      pinElementRef.current.title = newComment;
    }

    setPin(lightboxRef.current.pswp.currSlide.data.src, pinDetails);
  }

  const updatePin = () => {
    const pin = pinsRef.current[lightboxRef.current.pswp.currSlide.data.src];
    if(!pin || pin.length == 0) {
      return;
    }

    const [zoomWidth, zoomHeight] = getZoom();
    const [offsetX, offsetY] = getOffset();

    var image = imagesRef.current[lightboxRef.current.pswp.currSlide.index];

    const pointX = pin[0].pointX * zoomWidth / image.width + offsetX;
    const pointY = pin[0].pointY * zoomHeight / image.height + offsetY;

    if(pinElementRef.current != null) {
      pinElementRef.current.style.left = (pointX - 12) + "px";
      pinElementRef.current.style.top = (pointY - 24) + "px";
    }
  }

  const resetPin = () => {
    const pin = pinsRef.current[lightboxRef.current.pswp.currSlide.data.src];
    if(pinElementRef.current != null) {
      pinElementRef.current.style.display = (pin && pin.length > 0) ? "" : "none";
      pinElementRef.current.title = (pin && pin.length > 0) ? pin[0].comment : "";
      updatePin();
    }
  }

  const pinObserver = useRef(new MutationObserver(mutations =>{
    updatePin();
  }));


  const getNextImagePage = async () => {
    var res = await load(folder, tokenRef.current);
    imagesRef.current = imagesRef.current.concat(res.images);
    tokenRef.current = res.token;
    setReset(new Date().getTime());
    return res.images;
  }

  const navigateToImage = async () => {
    var num = Number.parseInt(imageNumber);
    if (Number.isNaN(num)) {
      alert("Please enter a valid number");
      return;
    }
    setLoading(true);
    try {
      for (let image of imagesRef.current) {
        if (Number.parseInt(getImageNumber(image.url)) === num) {
          setTimeout(() => lightboxRef.current.loadAndOpen(imagesRef.current.indexOf(image)), 1)
          return
        }
      }
      while (tokenRef.current) {
        var imagePage = await getNextImagePage();
        for (let image of imagePage) {
          if (Number.parseInt(getImageNumber(image.url)) === num) {
            setTimeout(() => lightboxRef.current.loadAndOpen(imagesRef.current.indexOf(image)), 1)
            return
          }
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      pswpModule: () => import('photoswipe'),
      wheelToZoom: true,
      loop: false,
      returnFocus: false,
      preload: [1, 2],
      imageClickAction: onPin,
      tapAction: onPin,
      mainClass: 'gallery-voting'
    });
    lightboxRef.current = lightbox;

    lightbox.on('uiRegister', function () {
      lightbox.pswp.ui.registerElement({
        name: 'image-number',
        order: 7,
        isButton: false,
        tagName: 'span',
        className: 'airs-image-number',

        onInit: (el, pswp) => {
          pswp.on('change', () => {
            el.innerHTML = "#" + getImageNumber(pswp.currSlide.data.src)
          });
        }
      });
      lightbox.pswp.ui.registerElement({
        name: 'vote-up-next',
        order: 8,
        isButton: true,
        tagName: 'button',
        className: 'pswp__button--arrow--next pswp__button--arrow airs-voteup-next',
        html: '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 60 60" width="60" height="60"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-arrow"></use><use xlink:href="#pswp__icn-arrow"></use></svg>',
        appendTo: 'wrapper',

        onClick: (event, el) => {
          vote(lightbox.pswp.currSlide.data.src, 1);
          var temp = { ...voteRef.current };
          if(!(lightbox.pswp.currSlide.data.src in temp)) {
            var image = imagesRef.current[lightbox.pswp.currSlide.index];
            image.vote = (image.vote ?? 0) + 1;
          }
          else {
            console.log(temp);
          }
          temp[lightbox.pswp.currSlide.data.src] = 1;
          voteRef.current = temp;
          lightbox.pswp.next();
          setReset(new Date().getTime());
        }
      });
      lightbox.pswp.ui.registerElement({
        name: 'vote-down-next',
        order: 9,
        isButton: true,
        tagName: 'button',
        className: 'pswp__button--arrow--next pswp__button--arrow airs-votedown-next',
        html: '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 60 60" width="60" height="60"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-arrow"></use><use xlink:href="#pswp__icn-arrow"></use></svg>',
        appendTo: 'wrapper',

        onClick: (event, el) => {
          vote(lightbox.pswp.currSlide.data.src, 0);
          var temp = { ...voteRef.current };
          if(lightbox.pswp.currSlide.data.src in temp)
          {
            var image = imagesRef.current[lightbox.pswp.currSlide.index];
            image.vote = (image.vote ?? 0) - 1;
            delete temp[lightbox.pswp.currSlide.data.src];
          }
          voteRef.current = temp;
          lightbox.pswp.next();
          setReset(new Date().getTime());
        }
      });
      lightbox.pswp.ui.registerElement({
        name: 'pin',
        order: 10,
        isButton: false,
        tagName: 'a',
        className: 'pin',
        html: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.06298 10.063 6.27212 12.2721 6.27212C14.4813 6.27212 16.2721 8.06298 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16755 11.1676 8.27212 12.2721 8.27212C13.3767 8.27212 14.2721 9.16755 14.2721 10.2721Z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.39409 5.48178 3.79417C8.90918 0.194243 14.6059 0.054383 18.2059 3.48178C21.8058 6.90918 21.9457 12.6059 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.97318 6.93028 5.17324C9.59603 2.3733 14.0268 2.26452 16.8268 4.93028C19.6267 7.59603 19.7355 12.0268 17.0698 14.8268Z" fill="currentColor" /></svg>',
        appendTo: 'wrapper',

        onInit: function(el, pswp) {
          pinElementRef.current = el
        }, 
  
        onClick: (event, el) => {
          el.style.display = "none";
          pinsRef.current[lightboxRef.current.pswp.currSlide.data.src] = []
          clearPin(lightboxRef.current.pswp.currSlide.data.src, "1");
        }
      });      
    });
    lightbox.on("change", () => {
      var element = document.getElementById(`${id}-${lightbox.pswp.currSlide.index}`);
      element.scrollIntoView();
      localStorage.setItem(folder, getImageNumber(lightbox.pswp.currSlide.data.src));

      pinObserver.current.disconnect();
      pinObserver.current.observe(lightbox.pswp.currSlide.container, { attributes : true, attributeFilter : ['style'] });
      pinObserver.current.observe(lightbox.pswp.currSlide.content.element, { attributes : true, attributeFilter : ['style'] });

      resetPin();
    });
    lightbox.addFilter('numItems', (numItems) => {
      return imagesRef.current.length;
    });
    lightbox.addFilter('itemData', (itemData, index) => {
      const image = imagesRef.current[index];
      return {
        alt: "",
        width: image.width,
        height: image.height,
        msrc: image.thumbnailUrl,
        src: image.url
      }
    });

    lightbox.init();

    return () => {
      pinObserver.current.disconnect();
      lightbox.destroy();
      lightbox = null;
      lightboxRef.current = null;
    };
  }, [folder, id]);

  return (
    <>
      {extended ? <form className="row g-3" onSubmit={e => { e.preventDefault(); navigateToImage(); }}>
        <div className='col-auto'>
          <label htmlFor="search" className='col-form-label'>Navigate to</label>
        </div>
        <div className="col-auto">
          <input type="search" className='form-control' id='search' placeholder="image number" autoFocus={true} value={imageNumber} onChange={e => setImageNumber(e.target.value)} disabled={loading} />
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3" disabled={loading}>Go</button>
        </div>
      </form> : <></>}
      <InfiniteScroll
        dataLength={imagesRef.current.length}
        next={getNextImagePage}
        hasMore={tokenRef.current ? true : false}
        loader={<h4>Loading...</h4>}
      >
        <div className="pswp-gallery" id={id}>
          {imagesRef.current.map((image, index) => (
            <a
              href={image.url}
              data-pswp-width={image.width}
              data-pswp-height={image.height}
              key={id + '-' + index}
              id={id + '-' + index}
              target="_blank"
              rel="noreferrer"
              onClick={e => { e.preventDefault(); lightboxRef.current.loadAndOpen(index) }}
            >
              <img src={image.thumbnailUrl} alt="" width={image.thumbWidth} height={image.thumbHeight} loading='lazy' />
              <span title='Image number & vote number'>#{getImageNumber(image.url)} &#x2605;{image.vote ?? 0}</span>
            </a>
          ))}
        </div>
      </InfiniteScroll>
    </>
  );
}
