import React, { Fragment } from 'react';
import { Link, Outlet, useLoaderData } from 'react-router-dom';
import { DetailsPage } from './DetailsPage';
import { SummaryPage } from './SummaryPage';
import { Navigation } from '../../api/common/Types';

export const Layout: React.FC = () => {
  const data = useLoaderData() as any;
  return <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link to="/" className="navbar-brand">AIRS</Link>
        <div className="collapse navbar-collapse">
          <div className="navbar-nav">
            {data.parents && data.parents.map((r: Navigation, i: number) => <Fragment key={i}>
              <Link to={`/${r.path}`} className='nav-item nav-link'>{r.title}</Link>
            </Fragment>)}
          </div>
        </div>
      </div>
    </nav>
    <div className="container mt-4">
      {data.images ? <DetailsPage data={data} /> : <SummaryPage data={data} />}
    </div>
  </>
}
