import React from 'react';
import { Details } from '../../api/common/Types';
import ReactMarkdown from 'react-markdown';
import Gallery from './Gallery';
import Preview from './Preview';

export interface DetailsPageProps {
  data: Details
}

export const DetailsPage: React.FC<DetailsPageProps> = ({ data }) => {
  return <>
    {(data.title || data.body || data.preview) && <div className="card mb-3">
      <div className="row no-gutters">
        {data.preview && <Preview images={[data.preview]} id="header" height={150} className="col-auto ms-2 mt-2 pe-0"/>}
        <div className="col">
          <div className="card-body">
            {data.title && <h1 className="card-title">{data.title}</h1>}
            {data.body && <ReactMarkdown className="card-text" children={data.body} />}
          </div>
        </div>
      </div>
    </div>}
    <Gallery images={data.images} token={data.token} votes={data.votes} pins={data.pins} id="gallery" extended={!document.location.href.endsWith("/random")}/>
  </>
}
